<template>
  <section class="stage">
    <div class="container-xl stage__container">
      <div class="stage__shapecontainer" v-if="layout !== 'image'">
        <StageDefaultShape :front="front" :frontlight="frontlight" :imagePosition="layout === 'image' ? imagePosition : 'right'" />
      </div>
      <div class="row align-items-center">
        <div class="col-sm-12" :class="[layout === 'default' ? 'col-md-8' : 'col-md-6', imagePosition === 'left' && layout === 'image' ? 'stage__reverse' : '']">
            <div class="card stage__card">
              <div class="card-body pl-0">
                <h5 v-if="overline" class="card-subtitle">{{ overline }}</h5>
                <h4 v-if="headline" class="card-title">{{ headline }}</h4>
                <div v-if="description" class="card-text" v-html="description"></div>
                <DefaultButton
                  class="card-button"
                  v-if="buttonText && layout === 'image'"
                  :button-text="buttonText"
                  :link="buttonLink"
                  :type="buttonType"
                  :iconButton="buttonIcon"
                  icon="cart3"
                  :variant="buttonColorVariant || 'primary'"
                />
                <a class="card-link mt-3" v-if="errorPage" :href="$routeHandler('/')">{{ $t('view.error.home_button') }}</a>
              </div>
            </div>
        </div>
        <div v-if="layout === 'image'" class="stage__image col-sm-12 col-md-6">
          <img :src="image" alt="Image" />
        </div>
        <div v-if="layout === 'balance'" class="col-sm-12 col-md-5 offset-md-1">
          <StageBalanceCard
            :cardTitle="cardTitle"
            :cardDesc="cardDesc"
            :cardCtaLabel="cardCtaLabel"
            :cardCtaType="cardCtaType"
            :cardCtaLink="cardCtaLink"
            :buttonColorVariant="buttonColorVariant"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import StageDefaultShape from './StageDefaultShape.vue';
  import StageBalanceCard from './StageBalanceCard.vue';
  import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';
  export default {
    data() {
      return {
        front: '',
        frontlight: ''
      }
    },
    components: {
      StageDefaultShape,
      StageBalanceCard,
      DefaultButton
    },
    props: {
      headline: {
        type: String,
        default: ''
      },
      overline: {
        type: String,
        default: ''
      },
      description: {
        type: String,
        default: ''
      },
      layout: {
        type: String,
        default: 'default'
      },
      cardTitle: {
        type: String,
        default: ''
      },
      cardDesc: {
        type: String,
        default: ''
      },
      cardCtaLabel: {
        type: String,
        default: ''
      },
      cardCtaType: {
        type: String,
        default: ''
      },
      cardCtaLink: {
        type: String,
        default: ''
      },
      image: {
        type: String,
        default: ''
      },
      imagePosition: {
        type: String,
        default: ''
      },
      errorPage: {
        type: Boolean,
        default: false
      },
      buttonText: {
        type: String,
        default: ''
      },
      buttonType: {
        type: String,
        default: 'default'
      },
      buttonLink: {
        type: String,
        default: '#'
      },
      buttonIcon: {
        type: Boolean,
        default: false
      },
      buttonColorVariant: {
        type: String,
        default: ''
      }
    },
    mounted() {
      const cssRoot = document.querySelector(':root');
      const docStyle = getComputedStyle(cssRoot);
      this.front = docStyle.getPropertyValue('--primary');
      this.frontlight = docStyle.getPropertyValue('--primary-bright') ? docStyle.getPropertyValue('--primary-bright') : docStyle.getPropertyValue('--primary');
    },
  };
</script>

<style lang="scss" scoped>
  .stage {
    z-index: 1;
    overflow: hidden;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;

    @include media-breakpoint-up(md) {
      padding: 3rem 0 3rem;
    }
    &__reverse {
      order: 2;
    }
    &__container {
      position: relative;
    }
    &__shapecontainer {
      display: none;
      position: absolute;
      width: calc(100% - 2rem);
      top: 50%;
      transform: perspective(1px) translateY(-50%);
      @include media-breakpoint-up(md) {
        display: block;
      }
    }
    &__image {
      display: flex;
      align-items: center;

      img {
        z-index: 1;
        flex-shrink: 0;
        width: 100%;
        border-radius: $border-radius;
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 2rem;
      }
    }
    // Special behavior for stage card. Only scoped needed.
    &__card {
      height: 100%;
      background-color: transparent;
      .card-body {
        @include media-breakpoint-down(sm) {
          padding: 0 0 1.5rem;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        .card-title {
          order: 2;
          margin-bottom: 1rem;
          font-weight: bold;
          font-size: 2rem;
        }
        .card-subtitle {
          order: 1;
          @extend %font-base;
          margin-bottom: 1rem;
        }
        .card-link {
          order: 4;
        }
        .card-text {
          order: 3;
        }
        .card-button {
          order: 5;
        }
      }
    }
  }
</style>
