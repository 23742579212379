<template>
  <div class="cookie-banner">
    <div class="cookie-banner__content">
      <h3>{{ $t('global.cookie.banner.headline') }}</h3>
      <p>
        {{ $t('global.cookie.banner.text') }}
        <a :href="privacyLink" rel="noopener">{{ $t('global.cookie.banner.data_protection') }}.</a>
      </p>
    </div>
    <div class="cookie-banner__buttons">
      <DefaultButton variant="primary" @click="$emit('open-modal')" :buttonText="$t('global.cookie.banner.settings')" :type="'button'" />
      <DefaultButton
        variant="primary"
        :buttonText="$t('global.cookie.banner.accept')"
        @click="$emit('accept-all')"
        :type="'button'"
        data-test-id="cookie-btn-accept-all"
      />
      <DefaultButton variant="primary" :buttonText="$t('global.cookie.banner.remove')" @click="$emit('remove-all')" :type="'button'" />
    </div>
  </div>
</template>

<script>
  import DefaultButton from '../DefaultElements/DefaultButton.vue';
  export default {
    components: {
      DefaultButton
    },
    computed: {
      privacyLink() {
        return this.$store.state.pages.additionalOptions.cookiePrivacyLink || this.$routeHandler('/privacy');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .cookie-banner {
    z-index: 999;
    top: -200px;
    right: 0;
    width: 100%;
    background-color: $white;
    position: fixed;
    -webkit-animation: expand 0.5s;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;

    &__content {
      max-width: 900px;
      width: 100%;
    }

    &__buttons {
      display: flex;
      justify-content: center;

      button {
        font-size: 12px;
      }
      button:not(:first-child) {
        margin-left: 10px;
      }
    }

    h3 {
      font-size: 20px;

      @media (max-width: 769px) {
        font-size: 18px;
      }
    }

    p {
      font-size: 16px;
      font-weight: 400;

      @media (max-width: 769px) {
        font-size: 13px;
      }
    }

    @-webkit-keyframes expand {
      0% {
        top: -200px;
      }
      100% {
        top: 0;
      }
    }
  }
</style>
