<template>
  <div class="row">
    <div class="col">
      <form @submit.prevent="submitVoucherCode">
        <p class="hide-element">
          <label name="bot-field">
            <input type="hidden" />
          </label>
        </p>
        <DefaultInput
          v-model.trim="codeInput"
          hide-label
          :state="inputFieldState"
          :error-text="errorText"
          promo-code
          :placeholder="inputPlaceholder"
          group-id="codeInputField"
          class="voucher-input mb-2"
          data-test="code-input-field"
        />
        <div v-if="hcaptchaKey && !websiteSettings.deny_captcha" class="hcaptcha-container">
          <VueHcaptcha ref="hcaptcha" :sitekey="hcaptchaKey" :language="$store.state.translation.locale" @error="onError" @verify="onSuccess" @expired="onExpired" />
        </div>
        <DefaultButton
          class="mt-2"
          type="submit"
          block
          variant="primary"
          :button-text="buttonText"
          :loading="loading"
          data-test="code-input-button"
          :class="buttonFontColor === 'dark' ? 'text-dark' : 'text-light'"
        />
      </form>
    </div>
  </div>
</template>

<script>
  import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';
  import { mapGetters, mapState } from 'vuex';
  import DefaultInput from 'building-blocks/components/DefaultElements/DefaultInput.vue';
  import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';
  import { loadRiskIdent } from '@/helpers/riskIdent.js';

  export default {
    name: 'CodeInputForm',
    props: {
      redirection: {
        type: String,
        default: ''
      },
      inputPlaceholder: {
        type: String,
        default: ''
      },
      buttonText: {
        type: String,
        default: ''
      },
      buttonFontColor: {
        type: String,
        default: ''
      }
    },
    components: {
      VueHcaptcha,
      DefaultInput,
      DefaultButton
    },
    data() {
      return {
        inputFieldState: null,
        codeInput: '',
        captchaToken: '',
        errorMessage: '',
        pxRatio: 0
      };
    },
    computed: {
      ...mapGetters({
        postPaymentType: 'basket/getPostPaymentType',
        riskIdentId: 'app/getRiskIdentId'
      }),
      ...mapState({
        basketGrossPrice: (state) => state.basket.grossPrice,
        loading: (state) => state.app.isLoading,
        websiteSettings: (state) => state.app.websiteSettings,
        prefilledVoucherCode: (state) => state.app.prefilledVoucherCode,
        prepaidValue: (state) => state.basket.prepaidValue,
        isUserLoggedIn: (state) => state.user.loggedIn,
        basketNumber: (state) => state.basket.basketNumber,
        isOpenShop: (state) => state.basket.isOpenShop
      }),

      errorText() {
        return this.errorMessage;
      },
      isCodeInputEmtpy() {
        return this.codeInput === '';
      },
      hcaptchaKey() {
        return process.env.VUE_APP_HCAPTCHA_SITE_KEY;
      }
    },
    mounted() {
      if (this.prefilledVoucherCode) {
        this.codeInput = this.prefilledVoucherCode;
      }
    },
    watch: {
      prefilledVoucherCode() {
        this.codeInput = this.prefilledVoucherCode;
      }
    },
    methods: {
      /**
       * Sets Focus on CodeInput
       * Get´s triggered from Parent Component, if Dropdown is visible
       */
      setFocusToInput() {
        document.getElementById('codeInputField').focus();
      },
      /**
       * Resets the InputField
       * Get´s triggered from Parent Component, if Dropdown is hidden
       */
      resetInputField() {
        this.inputFieldState = null;
        this.errorMessage = '';
        this.codeInput = '';
        this.$store.commit('app/SET_PREFILLED_VOUCHERCODE', null);
      },
      setLoading(state) {
        this.$store.commit('app/SET_LOADINGSTATE', state);
      },
      toast(message, error) {
        const toastData = {
          message,
          duration: 7
        };
        this.$store.commit('app/SET_TOAST', toastData);
      },
      onError(error) {
        if (!error.handled) {
          this.toast(this.$t('form.error.hcaptcha', { error }));
        }
      },
      onSuccess(captchaToken) {
        this.captchaToken = captchaToken;
      },
      async onExpired() {
        await this.$refs.hcaptcha.reset();
      },
      submitVoucherCode() {
        this.inputFieldState = null;

        if (this.isCodeInputEmtpy) {
          this.inputFieldState = false;
          this.errorMessage = this.$t('api-errors.voucher.empty');
        } else if (this.isUserLoggedIn || this.websiteSettings.deny_captcha) {
          this.validateVoucherInput();
        } else {
          this.verifyHcaptcha();
        }
      },
      async validateVoucherInput() {
        try {
          this.setLoading(true);
          await this.$store.dispatch('basket/createBasket');
          await this.$store.dispatch('basket/verifyLoginCode', this.codeInput);
          await this.$store.dispatch('user/logIn');
          await this.$store.dispatch('product/fetchProducts');
          
          const allProducts = await this.$store.getters['product/getAllProducts'];
          await this.$store.dispatch('filter/setPriceRangeAndProducts', allProducts);
          await this.$store.commit('filter/SET_ALL_PRODUCTS', allProducts);
          await this.$store.commit('filter/SET_LOADED_PRODUCTS', allProducts);
          
          this.resetInputField();
          this.checkPaymentmethods();
          loadRiskIdent({token: this.basketNumber, apiId: this.riskIdentId});
          this.setLoading(false);
          if (this.redirection !== '') this.$router.push(this.$routeHandler(this.redirection));
          this.$emit('success');
        } catch (error) {
            this.setLoading(false);
        
            // If the Voucher is valid for another Shop the ErrorObj will contain a redirection_url, which we use to redirect the user.
            // If the User is logged in and just adds another invalid Voucher he will not be redirected
            if (!this.isUserLoggedIn && error.response?.data?.error_fields?.redirection_url) {
              window.location.href = error.response.data.error_fields.redirection_url
            }
            // We just show the Input Error if the User is not getting redirected
            else if (error.displayInComponent) {
              this.inputFieldState = false;
              this.errorMessage = error.message;
            }
          }
      },
      async verifyHcaptcha() {
        try {
          this.setLoading(true);
          const response = await this.$api.verifyCaptchaToken(this.captchaToken);
          const tokenValid = response.success;
          if (tokenValid) {
            await this.$refs.hcaptcha.reset();
            this.validateVoucherInput();
          } else {
            this.setLoading(false);
            throw this.toast(this.$t('form.error.check_hcaptcha'));
          }
        } catch (error) {
          this.setLoading(false);
          await this.$refs.hcaptcha.reset();
        }
      },
      /**
       * Check if other payment methodes besides prepaidvalue are needed after codeinput, if not then they get removed
       */
      checkPaymentmethods() {
        if (this.basketGrossPrice <= this.prepaidValue) {
          this.deleteCurrentPostPaymentOptionFromBasket();
        }
      },
      deleteCurrentPostPaymentOptionFromBasket() {
        const currentPostPayment = this.postPaymentType;
        if (currentPostPayment) {
          const currentPostPaymentType = currentPostPayment.payment_type_id;
          this.$store.dispatch('basket/removePaymentType', currentPostPaymentType);
        }
      }
    }
  };
</script>

<style lang="scss">
  .hide-element {
    display: none;
  }
  .voucher-input {
    .invalid-feedback {
      text-align: center;
      font-size: $font-size-tiny;
    }
  }
  .hcaptcha-container {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    transform: scale(0.77);
  }
</style>
