import { createWebHistory, createRouter } from 'vue-router';
import store from '@/store';
import routes from './routes.js';
import { loadRiskIdent } from '@/helpers/riskIdent.js';
import { routeHandler, setColors } from '@/helpers/utils.js';
import { getCurrentLanguage } from 'mgnl-building-blocks/helpers/mgnlApiHelper';
// A querystring parsing and stringifying library with some added security.
// https://www.npmjs.com/package/qs
import qs from 'qs';

const router = createRouter({
  history: createWebHistory(),
  routes,
  parseQuery(query) {
    if (query.startsWith('query')) {
      const queryArray = query.split('=');
      return { query: queryArray[1] };
    }
    const parsedQuery = qs.parse(query);
    return parsedQuery;
  },
  stringifyQuery(query) {
    const result = qs.stringify(query);

    return result ? result : '';
  },
  scrollBehavior(to, from, savedPosition) {
    if (to.hash && to.hash !== from.hash) {
      const el = document.querySelector(to.hash);
      if (el) el.scrollIntoView({ behavior: 'smooth' });
    } else if (from.path === to.path) {
      return;
    } else if(to.name === 'product-overview' && savedPosition) {
      return savedPosition;
    } else {
      document.getElementById('app').scrollIntoView();
    }
  }
});

// Global route guard to check only static available pages for loggedIn user and non-loggedIn user from meta data.
// Dynamic pages from CMS will be checked in PageLoader
router.beforeEach(async (to, from) => {
  // Reset session ID after confirmation page
  if (from.name === 'checkout-confirmation') {
    window.location.replace(to.href);
    return false;
  }

  const mgnlMode = store.state.app.mgnlMode;
  const appLoaded = store.state.app.appLoaded;
  let userLoggedIn = store.state.user.loggedIn;
  let websiteSettings;
  let paymentInProcess;
  let handshakeProduct;
  let nav;

  // On reload of the Application
  if (!['not-found', 'error', 'handshake-failed', 'handshake-login'].includes(to.name) && !appLoaded) {
    // Initialize the Shop and Create a Session
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get("sid");
    const handshakeQueryParam = urlParams.get("query");
    const initialLanguage = store.getters['translation/getInitialLanguage'](to.path);
    let bestChoicePaymentType;

    try {
      websiteSettings = await store.dispatch('app/initShop', {sessionId, language: initialLanguage});
    } catch (error) {
      return handleError(500);
    }

    const overrideLang = websiteSettings?.website_attribute_language_override?.toLowerCase();
    if(!mgnlMode && overrideLang) {
      await store.dispatch('translation/setOverrideLocale', overrideLang);
    } else {
      const availableLangs = websiteSettings?.website_attribute_selectable_languages?.toLowerCase();
      const defaultLang = websiteSettings?.website_attribute_language_default?.toLowerCase();
      const language = mgnlMode ? getCurrentLanguage(to.path) : websiteSettings?.application_language?.toLowerCase();

      await store.dispatch('translation/setAvailableLocales', availableLangs);
      await store.dispatch('translation/setFallbackLocale', defaultLang);
      await store.commit('translation/SET_LOCALE', language);
      await store.dispatch('translation/setYupLocale');
    }

    if(!mgnlMode && to.params.lang && store.state.translation.locales.length > 1 && !store.state.translation.locales.includes(to.params.lang)) {
      return handleError(404);
    }

    bestChoicePaymentType = websiteSettings?.payment_types.some(payment => payment.id === 2);

    if (to.name === 'voucher-overview' && !bestChoicePaymentType) {
      return routeHandler('/');
    }

    userLoggedIn = websiteSettings?.session_info.logged_in;
    const websiteType = websiteSettings?.website_type_id;
    const riskIdentId = websiteSettings?.riskident_snippetid;

    const saferpayStatus = to.query.saferpay_status;
    if (saferpayStatus) {
      await store.dispatch('basket/paymentInProcess', 'false'); 
    }

    if (userLoggedIn) {
      await store.dispatch('basket/loadBasket');
      await loadProducts();
    } else if (!mgnlMode && (websiteType === 'WOB' || websiteType === 'PUN') && (handshakeQueryParam || (!websiteSettings?.wob_account_login && !websiteSettings?.wob_coupon_login))) {
      // Handshake Login is automatically active on WOB and PUN Shops or if the Coupon Login and Account Login is deactivated
      let handshake;
      try {
        handshake = await store.dispatch('app/verifyHandShake', {handshakeQueryParam, sessionId});
      } catch (error) {
        return routeHandler('/handshake-failed');
      }
      handshakeProduct = handshake?.product_number;
      userLoggedIn = await handleHandshakeLogin(handshake);
      if (userLoggedIn) {
        loadRiskIdent({token: store.state.basket.basketNumber, apiId: riskIdentId});
      } else {
        return routeHandler('/handshake-failed');
      }
    } else if (websiteType === 'SHP') {
      // Open shop
      userLoggedIn = await openShop();
      if (userLoggedIn) loadRiskIdent({token: store.state.basket.basketNumber, apiId: riskIdentId});
    } else if (mgnlMode) {
      await store.dispatch('product/addCatalog', { catalogId: websiteSettings?.generation_profile_id || websiteSettings?.default_catalog_id, loaded: false });
      await loadProducts();
    }
    nav = await store.dispatch('pages/initNav', store.state.translation.locale);
    if(!nav) {
      return handleError(500);
    }
  }

  if(['not-found', 'error'].includes(to.name)) {
    store.commit('app/SET_SHOWLOADINGANIMATION', false);
    store.commit('app/SET_APPLOADED', true);
    return true;
  }

  if (userLoggedIn) paymentInProcess = await store.dispatch('basket/paymentInProcess', '');

  const content = await store.dispatch('pages/loadPage', {path: to.path, lang: store.state.translation.locale});
  if(!content && !to.meta.static) {
    return handleError(404);
  }
  const navigation = mgnlMode || setNavigation({ to, loggedIn: userLoggedIn, content, paymentInProcess, handshakeProduct });
  if(navigation) store.commit('pages/SET_CURRENT_PAGE', content);

  if (!appLoaded && userLoggedIn) handleShopCustomization();

  store.commit('app/SET_APPLOADED', true);
  store.commit('app/SET_SHOWLOADINGANIMATION', false);
  return routeHandler(navigation);
});

router.beforeResolve((to, from) => {
  if(to.path !== from.path) {
    const content = store.state.pages.currentPage;
    const pagename = content?.title || (to.meta?.title + (to.params?.product_number ? ' - ' + store.getters['product/getProduct'](to.params.product_number)?.name : '')) || document.title;
    store.dispatch('etracker/et_eC_Wrapper', {et_et: store.state.etracker.etrackerId, et_pagename: pagename});
  }
});

router.afterEach((to, from) => {
  if(to.path !== from.path) {
    document.body.classList.remove('stop-scrolling');
  }
});

export default router;

// **********************************
// Helper Functions
// **********************************

/**
 * Backup function if request fails, show error page
 */
function handleError(errorCode) {
  store.commit('app/SET_SHOWLOADINGANIMATION', false);
  store.commit('app/SET_APPLOADED', true);
  router.push({ path: routeHandler(errorCode !== 404 ? '/error' : '/not-found') });
  return false;
}

function handleShopCustomization() {
  const shopCustomization = store.state.basket.shopCustomization;
  if(shopCustomization) setColors(shopCustomization.primary_color, shopCustomization.secondary_color);
}

/**
 * Create a navigation based on Login State and Route-Object of the Router.
 * @param {Object} to  - https://router.vuejs.org/api/interfaces/RouteLocationNormalized.html
 * @param {boolean} loggedIn - The Login State of the User.
 * @return {boolean | string} - true: navigation proceeds / "url" : redirect to that url
 */
function setNavigation({ to, loggedIn, content, paymentInProcess, handshakeProduct }) {
  const isPunchout = store.getters['punchout/isPunchout'];
  const isPunchoutInspect = store.getters['punchout/isPunchoutInspect'];
  const isPunchoutEdit = store.getters['punchout/isPunchoutEdit'];
  const appLoaded = store.state.app.appLoaded;
  const minimalMode = store.state.pages.additionalOptions.minimalMode;
  
  if (loggedIn) {
    if(isPunchout) {
      if(to.meta.punchout === false && to.name !== 'welcome') {
        return '/welcome'
      }
      if((isPunchoutInspect || isPunchoutEdit) && to.name !== 'basket') {
        if(isPunchoutInspect) {
          return appLoaded ? false : '/basket';
        }
        if(!appLoaded) {
          return '/basket';
        }
      }
    }

    if (handshakeProduct && to.path !== '/product-details/' + handshakeProduct) {
      return '/product-details/' + handshakeProduct;
    }

    if (paymentInProcess == 'true' && to.name !== 'payment-in-process') {
      return '/payment-in-process';
    }

    if (to.name == 'product-overview' && minimalMode == 'active') {
      return '/welcome';
    }

    return to.name !== 'welcome' && to.meta.needsLogin === false || (content && content.securityGuard && !content.securityGuard.includes('loggedin')) ? '/welcome' : true;
  } else {
    return to.name !== '/' && to.meta.needsLogin === true || (content && content.securityGuard && !content.securityGuard.includes('loggedout')) ? '/' : true;
  }
}

/**
 * Handles the Handshake Login and returns a Boolean for Status
 * @param {Object} handshake
 * @return {boolean}
 */
async function handleHandshakeLogin(handshake) {
  if (!handshake.valid) return false;

  // Set flag for handshake in app.js
  store.commit('app/SET_IS_HANDSHAKE', true);
  
  //Log in User via Handshake Login ( Set Login Flag to true in Session)
  await store.dispatch('app/handleHandshakeLogin');

  //Create Basket
  await store.dispatch('basket/createBasket', null);

  if(handshake.coupon && handshake.isValidCoupon) {
    await store.dispatch('basket/verifyLoginCode', handshake.coupon);
    handleShopCustomization();
  }

  //Fetch Products
  await loadProducts();

  return true;
}

async function openShop() {
  try {
    store.commit('app/SET_LOADINGSTATE', true);

    // Set flag for open shop in app.js
    store.commit('app/SET_IS_OPEN_SHOP', true);
  
    //Create Basket
    await store.dispatch('basket/createBasket', null);
  
    // Set Login Flag to true in State
    await store.dispatch('user/logIn', true);
    store.commit('app/SET_HEADER_LOGGEDIN', true);
    store.commit('app/SET_LOADINGSTATE', false);
  
    //Fetch Products
    await loadProducts();
  
    return true;
  } catch (error) {
    store.commit('app/SET_LOADINGSTATE', false);
    router.push({ path: routeHandler('/error-open') });
    return false;
  }
}

async function loadProducts() {
  await store.dispatch('product/fetchProducts');
  const allProducts = store.getters['product/getAllProducts'];
  store.dispatch('filter/setPriceRangeAndProducts', allProducts);
  store.commit('filter/SET_ALL_PRODUCTS', allProducts);
  store.commit('filter/SET_LOADED_PRODUCTS', allProducts);
}
