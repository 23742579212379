export default function loadCouponing(data) {  
  window.sovApplication = window.sovApplication || {};
  function lockOnEvent(obj) {
    Object.defineProperty(obj, "onEvent", {
      get() {
        return () => {};
      },
      set() {},
      configurable: false,
      enumerable: true,
    });
    return obj;
  }
  let _removalObserver = {};
  _removalObserver = lockOnEvent(_removalObserver);
  Object.defineProperty(window.sovApplication, "removalObserver", {
    get() {
      return _removalObserver;
    },
    set(newVal) {
      _removalObserver = lockOnEvent(newVal || {});
    },
    configurable: true,
    enumerable: true,
  });
  window.sovIframes = window.sovIframes || [];

  window.sovIframes.push({
    trafficSourceNumber: data.sourceNumber,
    trafficMediumNumber: data.mediumNumber,
    sessionID: data.sessionID,
    timestamp: Math.floor(Date.now() / 1000),
    integrationType: "genericScript-1.3.3"
  });

  // Append Sovendus script to the head
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.src =
    window.location.protocol +
    "//api.sovendus.com/sovabo/common/js/flexibleIframe.js";
  document.head.appendChild(script);
}