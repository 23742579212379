<template>
  <div class="page page--error">
    <StageDefault 
      textstage 
      :headline="$t('view.error.page.open.headline')"
      :description="$t('view.error.page.open.text')"
      :errorPage="true" />
  </div>
</template>

<script>
  import StageDefault from '@/components/Stage/StageDefault.vue';
  export default {
    components: { StageDefault },
    computed: {
      error() {
        return this.$route.meta.error;
      }
    }
  };
</script>
