<template>
  <transition name="toast" appear>
    <div v-show="toastConfig" class="toast" :class="toastData.colorVariant">
      <div class="toast-header d-flex" :style="`background-color: var(--${toastData.colorVariant}); color: var(--light)`">
        <strong class="me-auto">{{ toastData.title }}</strong>
        <DefaultButton :type="'button'" :buttonText="'X'" :variant="`${toastData.colorVariant}`" @click="dismiss" />
      </div>
      <div class="toast-body text-wrap" :style="`background-color: var(--${toastData.colorVariant}-light)`">
        <p>{{ toastData.message }}</p>
        <DefaultButton
          v-if="toastData.link"
          type="url"
          :buttonText="toastData.buttonText"
          :variant="`${toastData.colorVariant}`"
          :href="toastData.link"
          :style="`color: var(--${toastData.colorVariant}-lightest)`"
        />
      </div>
    </div>
  </transition>
</template>

<script>
  import DefaultButton from '../components/DefaultElements/DefaultButton.vue';

  export default {
    components: {
      DefaultButton
    },
    computed: {
      toastConfig() {
        return this.$store.state.app.toastConfig;
      }
    },
    data() {
      return {
        timeout: null,
        toastData: {
          title: this.$t('api-errors.toast-title'),
          message: '',
          colorVariant: 'primary',
          position: 'top-right',
          duration: 0,
          buttonText: '',
          link: ''
        }
      };
    },
    watch: {
      toastConfig(config) {
        if (config) {
          this.toastData = Object.assign(this.toastData, config);
          if (this.toastData.duration) {
            this.timeout = setTimeout(() => {
              this.dismiss();
            }, this.toastData.duration * 1000);
          }
        }
      }
    },
    methods: {
      dismiss() {
        clearTimeout(this.timeout);
        this.$store.commit('app/SET_TOAST', null);
      }
    },
    mounted() {
      if(this.toastConfig) {
        this.toastData = Object.assign(this.toastData, this.toastConfig);
        if (this.toastData.duration) {
          this.timeout = setTimeout(() => {
            this.dismiss();
          }, this.toastData.duration * 1000);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .toast {
    position: fixed;
    top: 0;
    right: 0;
    margin: 1rem;
    max-width: 400px;
    width: 100%;
    z-index: $z-index-highest;
    text-align: left;
    border: none;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.12);
    opacity: 0.95;
    @include media-breakpoint-down(md) {
      margin: 0;
    }

    .toast-header {
      padding: 0.1rem 0.5rem;
      justify-content: space-between;
      align-items: center;
      border-top-right-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
    }

    .toast-body {
      padding: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }

    button {
      padding: 0.2rem 0.5rem;
      color: $light;
    }
  }

  .top-right {
    right: 0;
    top: 0;
  }

  .top-left {
    top: 0;
    left: 0;
  }

  /* enter transitions */
  .toast-enter-active {
    animation: wobble 0.5s ease;
  }
  /* leave transitions */
  .toast-leave-to {
    opacity: 0;
    transform: translateY(-60px);
  }
  .toast-leave-active {
    transition: all 0.7s ease;
  }
  @keyframes wobble {
    0% {
      transform: translateY(-100px);
      opacity: 0;
    }
    50% {
      transform: translateY(0px);
      opacity: 0.3;
    }
    60% {
      transform: translateX(8px);
      opacity: 0.3;
    }
    70% {
      transform: translateX(-8px);
      opacity: 0.7;
    }
    80% {
      transform: translateX(4px);
      opacity: 0.7;
    }
    90% {
      transform: translateX(-4px);
      opacity: 1;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
</style>
