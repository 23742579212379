export default [
  {
    // Service not availabe
    error_name: 'INTERNAL_SERVER_ERROR',
    error_code: 'BFFE100',
    messageKey: 'api-errors.general.INTERNAL_SERVER_ERROR',
    displayInComponent: false,
    additionalMessage: true,
    additionalMessageKey: 'api-errors.general.INTERNAL_SERVER_ERROR',
    additionalMessageType: 'toast',
    additionalMessageVariant: 'primary',
    params: null
  },

  {
    // Gateway Timeout
    error_name: 'GATEWAY_TIMEOUT',
    error_code: 'TIMEOUT',
    messageKey: 'api-errors.general.GATEWAY_TIMEOUT',
    displayInComponent: false,
    additionalMessage: true,
    additionalMessageKey: 'api-errors.general.GATEWAY_TIMEOUT',
    additionalMessageType: 'toast',
    additionalMessageVariant: 'primary',
    params: null
  },

  {
    // Error on CVision Side
    error_name: 'CVISION_ERROR',
    error_code: 'BFFE100',
    messageKey: 'api-errors.general.INTERNAL_SERVER_ERROR',
    displayInComponent: false,
    additionalMessage: true,
    additionalMessageKey: 'api-errors.general.INTERNAL_SERVER_ERROR',
    additionalMessageType: 'toast',
    additionalMessageVariant: 'primary',
    params: null
  },

  {
    // IP was blocked
    error_name: 'UNAUTHORIZED',
    error_code: 'BFFE016',
    messageKey: 'api-errors.general.UNAUTHORIZED',
    displayInComponent: false,
    additionalMessage: true,
    additionalMessageKey: 'api-errors.general.UNAUTHORIZED',
    additionalMessageType: 'toast',
    additionalMessageVariant: 'primary',
    params: null
  },

  {
    // Session is expired
    error_name: 'SESSION_EXPIRED',
    error_code: 'BFFE021',
    messageKey: 'api-errors.general.SESSION_EXPIRED',
    displayInComponent: false,
    additionalMessage: true,
    additionalMessageKey: 'api-errors.general.SESSION_EXPIRED',
    additionalMessageType: 'toast',
    additionalMessageVariant: 'primary',
    params: null
  },

  {
    // No Session ID provided
    error_name: 'SESSION_REQUIRED',
    error_code: 'BFFE041',
    messageKey: 'api-errors.general.SESSION_REQUIRED',
    displayInComponent: false,
    additionalMessage: true,
    additionalMessageKey: 'api-errors.general.SESSION_REQUIRED',
    additionalMessageType: 'toast',
    additionalMessageVariant: 'primary',
    params: null
  },

  {
    // When the user tries to add a product to the shopping cart but has not yet set the important notes checkbox.
    error_name: 'MISSING_IMPORTANT_HINT_CHECKBOX',
    error_code: 'BFFE023',
    messageKey: 'api-errors.${errorLabelName}.MISSING_IMPORTANT_HINT_CHECKBOX',
    displayInComponent: false,
    additionalMessage: true,
    additionalMessageKey: 'api-errors.${errorLabelName}.MISSING_IMPORTANT_HINT_CHECKBOX',
    additionalMessageType: 'toast',
    additionalMessageVariant: 'primary',
    params: null
  },
  {
    // If the limit of the shop was exceeded and cannot be increased again.
    error_name: 'WEBSITE_LIMIT_EXCEEDED',
    error_code: 'BE007',
    messageKey: 'api-errors.${errorLabelName}.QUANTITY_LIMIT_EXCEEDED',
    displayInComponent: false,
    additionalMessage: true,
    additionalMessageKey: 'api-errors.${errorLabelName}.QUANTITY_LIMIT_EXCEEDED',
    additionalMessageType: 'toast',
    additionalMessageVariant: 'primary',
    params: null
  },
  {
    // If the limit of the shop was exceeded and cannot be increased again.
    error_name: 'DAILY_LIMIT_EXCEEDED',
    error_code: 'ABE007',
    messageKey: 'api-errors.${errorLabelName}.DAILY_LIMIT_EXCEEDED',
    displayInComponent: true,
    additionalMessage: false,
    additionalMessageKey: 'api-errors.${errorLabelName}.DAILY_LIMIT_EXCEEDED',
    additionalMessageType: 'toast',
    additionalMessageVariant: 'primary',
    params: null
  },
  {
    // If the minimum order limit was not reached. (minimum_order_value: XXX)
    error_name: 'MINIMUM_ORDER_LIMIT_ERROR',
    error_code: 'BE017',
    messageKey: 'api-errors.${errorLabelName}.MINIMUM_ORDER_LIMIT_ERROR',
    displayInComponent: false,
    additionalMessage: true,
    additionalMessageKey: 'api-errors.${errorLabelName}.MINIMUM_ORDER_LIMIT_ERROR',
    additionalMessageType: 'toast',
    additionalMessageVariant: 'primary',
    params: ['minimum_order_value', 'currency']
  },
  {
    // If the maximum order limit has been exceeded. (maximum_order_value: XXX)
    error_name: 'MAXIMUM_ORDER_LIMIT_ERROR',
    error_code: 'BE018',
    messageKey: 'api-errors.${errorLabelName}.MAXIMUM_ORDER_LIMIT_ERROR',
    displayInComponent: false,
    additionalMessage: true,
    additionalMessageKey: 'api-errors.${errorLabelName}.MAXIMUM_ORDER_LIMIT_ERROR',
    additionalMessageType: 'toast',
    additionalMessageVariant: 'primary',
    params: ['maximum_order_value', 'currency']
  },
  {
    // If the minimum virtual order limit was not reached (minimum_virtual_order_value: XXX)
    error_name: 'MINIMUM_VIRTUAL_ORDER_LIMIT_ERROR',
    error_code: 'BE019',
    messageKey: 'api-errors.${errorLabelName}.MINIMUM_VIRTUAL_ORDER_LIMIT_ERROR',
    displayInComponent: false,
    additionalMessage: true,
    additionalMessageKey: 'api-errors.${errorLabelName}.MINIMUM_VIRTUAL_ORDER_LIMIT_ERROR',
    additionalMessageType: 'toast',
    additionalMessageVariant: 'primary',
    params: ['minimal_virtual_order_value', 'currency']
  },
  {
    // If the maximum virtual order limit has been exceeded. (maximum_virtual_order_value: XXX)
    error_name: 'MAXIMUM_VIRTUAL_ORDER_LIMIT_ERROR',
    error_code: 'BE020',
    messageKey: 'api-errors.${errorLabelName}.MAXIMUM_VIRTUAL_ORDER_LIMIT_ERROR',
    displayInComponent: false,
    additionalMessage: true,
    additionalMessageKey: 'api-errors.general.INTERNAL_SERVER_ERROR',
    additionalMessageType: 'toast',
    additionalMessageVariant: 'primary',
    params: ['maximum_virtual_order_value', 'currency']
  },
  {
    // If the minimum number of items in the shopping cart is not reached. (minimum_item_count: XXX)
    error_name: 'MINIMUM_ITEM_LIMIT_ERROR',
    error_code: 'BE021',
    messageKey: 'api-errors.${errorLabelName}.MINIMUM_ITEM_LIMIT_ERROR',
    displayInComponent: false,
    additionalMessage: true,
    additionalMessageKey: 'api-errors.${errorLabelName}.MINIMUM_ITEM_LIMIT_ERROR',
    additionalMessageType: 'toast',
    additionalMessageVariant: 'primary',
    params: ['minimum_item_count']
  },
  {
    // When the maximum number of items in the shopping cart has been exceeded. (maximum_item_count: XXX)
    error_name: 'MAXIMUM_ITEM_LIMIT_ERROR',
    error_code: 'BE022',
    messageKey: 'api-errors.${errorLabelName}.MAXIMUM_ITEM_LIMIT_ERROR',
    displayInComponent: false,
    additionalMessage: true,
    additionalMessageKey: 'api-errors.${errorLabelName}.MAXIMUM_ITEM_LIMIT_ERROR',
    additionalMessageType: 'toast',
    additionalMessageVariant: 'primary',
    params: ['maximum_item_count']
  },
  {
    // If the maximum/minimum order value for a particular product was not met.
    error_name: 'PRODUCT_VALUE_LIMIT_ERROR',
    error_code: 'BE023',
    messageKey: 'api-errors.${errorLabelName}.PRODUCT_VALUE_LIMIT_ERROR',
    displayInComponent: false,
    additionalMessage: true,
    additionalMessageKey: 'api-errors.general.INTERNAL_SERVER_ERROR',
    additionalMessageType: 'toast',
    additionalMessageVariant: 'primary',
    params: ['product_name']
  },
  {
    //If the maximum/minmum virtual order value for a given product was not met.
    error_name: 'PRODUCT_VIRTUAL_VALUE_LIMIT_ERROR',
    error_code: 'BE024',
    messageKey: 'api-errors.${errorLabelName}.PRODUCT_VIRTUAL_VALUE_LIMIT_ERROR',
    displayInComponent: false,
    additionalMessage: true,
    additionalMessageKey: 'api-errors.${errorLabelName}.PRODUCT_VIRTUAL_VALUE_LIMIT_ERROR',
    additionalMessageType: 'toast',
    additionalMessageVariant: 'primary',
    params: null
  },
  {
    // If the maximum / minimum order quantity for a particular product was not met.
    error_name: 'PRODUCT_ITEM_COUNT_LIMIT_ERROR',
    error_code: 'BE025',
    messageKey: 'api-errors.${errorLabelName}.PRODUCT_ITEM_COUNT_LIMIT_ERROR',
    displayInComponent: false,
    additionalMessage: true,
    additionalMessageKey: 'api-errors.${errorLabelName}.PRODUCT_ITEM_COUNT_LIMIT_ERROR',
    additionalMessageType: 'toast',
    additionalMessageVariant: 'primary',
    params: null
  },
  {
    // If there is missing budget to pay for the shopping cart. (missing_budget: XXX)
    error_name: 'BASKET_BUDGET_ERROR',
    error_code: 'BE026',
    messageKey: 'api-errors.${errorLabelName}.BASKET_BUDGET_ERROR',
    displayInComponent: false,
    additionalMessage: true,
    additionalMessageKey: 'api-errors.${errorLabelName}.BASKET_BUDGET_ERROR',
    additionalMessageType: 'toast',
    additionalMessageVariant: 'primary',
    params: null
  },

  {
    // Entered BC code was invalid
    error_name: 'INVALID_BESTCHOICE_CODE',
    error_code: 'BFFE020',
    messageKey: 'api-errors.${errorLabelName}.INVALID_BESTCHOICE_CODE',
    displayInComponent: true,
    additionalMessage: true,
    additionalMessageKey: 'api-errors.voucher.invalid',
    additionalMessageType: 'alert',
    additionalMessageVariant: 'primary',
    params: ['remaining_attemps']
  },
  {
    // Too many failed attempts while adding a voucher code
    error_name: 'LOGIN_DENIED',
    error_code: 'BFFE042',
    messageKey: 'api-errors.${errorLabelName}.INVALID_BESTCHOICE_CODE',
    displayInComponent: true,
    additionalMessage: true,
    additionalMessageKey: 'api-errors.voucher.input_blocked',
    additionalMessageType: 'alert',
    additionalMessageVariant: 'danger',
    params: ['duration']
  },
  {
    // Same BC Code was entered multiple times
    error_name: 'VOUCHER_PAYMENT_ADD_CODE_DUPLICATE',
    error_code: 'PE005',
    messageKey: 'api-errors.${errorLabelName}.ALREADY_ENTERED_CODE',
    displayInComponent: true,
    additionalMessage: false,
    additionalMessageKey: null,
    additionalMessageType: null,
    additionalMessageVariant: null,
    params: null
  },
  {
    // BC Code with wrong merchant catalog was added to a basekt, which already contains a BC Code with a different merchant catalog.
    error_name: 'VOUCHER_PAYMENT_ADD_CODE_DUPLICATE',
    error_code: 'PE006',
    messageKey: 'api-errors.${errorLabelName}.CODES_NOT_COMBINABLE',
    displayInComponent: true,
    additionalMessage: false,
    additionalMessageKey: null,
    additionalMessageType: null,
    additionalMessageVariant: null,
    params: null
  },
  {
    // Entered ACE code was invalid
    error_name: 'INVALID_ACE_CODE',
    error_code: 'BFFE029',
    messageKey: 'api-errors.${errorLabelName}.INVALID_ACE_CODE',
    displayInComponent: true,
    additionalMessage: false
  },
  {
    // Entered Credentials are invalid
    error_name: 'INVALID_CREDENTIALS',
    error_code: 'BFFE050',
    messageKey: 'api-errors.${errorLabelName}.INVALID_CREDENTIALS',
    displayInComponent: true,
    additionalMessage: false
  },
  {
    // Entered passwords do not match
    error_name: 'PASSWORD_NOT_MATCHING',
    error_code: 'PASS_001',
    messageKey: 'api-errors.${errorLabelName}.PASSWORD_NOT_MATCHING',
    displayInComponent: true,
    additionalMessage: false,
  },
  {
    // Entered password is too easy to guess
    error_name: 'PASSWORD_TOO_EASY',
    error_code: 'PASS_002',
    messageKey: 'api-errors.${errorLabelName}.PASSWORD_TOO_EASY',
    displayInComponent: true,
    additionalMessage: false,
  },
  {
    // Entered password is the same as the old one
    error_name: 'OLD_PASSWORD',
    error_code: 'PASS_003',
    messageKey: 'api-errors.${errorLabelName}.OLD_PASSWORD',
    displayInComponent: true,
    additionalMessage: false,
  },
  {
    // Entered password is the same as the old one
    error_name: 'PASSWORD_EMPTY',
    error_code: 'VALI_001',
    messageKey: 'api-errors.${errorLabelName}.PASSWORD_EMPTY',
    displayInComponent: true,
    additionalMessage: false,
  },
  {
    // Too many failed attempts while entering user credentials
    error_name: 'USER_LOGIN_DENIED',
    error_code: 'BFFE051',
    messageKey: 'api-errors.${errorLabelName}.INVALID_CREDENTIALS',
    displayInComponent: true,
    additionalMessage: true,
    additionalMessageKey: 'api-errors.login.login_blocked',
    additionalMessageType: 'alert',
    additionalMessageVariant: 'danger',
    params: ['duration']
  },
];
