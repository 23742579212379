export default [
  {
    path: '/:lang?/welcome',
    name: 'welcome',
    component: () => import('@/pages/welcome.vue'),
    meta: {
      title: 'Welcome',
      static: true,
      needsLogin: true
    }
  },
  {
    path: '/:lang?/new-password',
    name: 'new-password',
    component: () => import('@/pages/new-password'),
    meta: {
      title: 'New password',
      static: true,
      needsLogin: false
    }
  },
  {
    path: '/:lang?/voucher-overview',
    name: 'voucher-overview',
    component: () => import('@/pages/voucher-overview.vue'),
    meta: {
      title: 'Voucher overview',
      static: true,
      needsLogin: true,
      punchout: false
    }
  },
  {
    path: '/:lang?/product-overview',
    name: 'product-overview',
    component: () => import('@/pages/product-overview.vue'),
    meta: {
      title: 'Product overview',
      static: true,
      needsLogin: true
    }
  },
  {
    path: '/:lang?/product-details/',
    redirect: (to) => {
      return '/';
    }
  },
  {
    path: '/:lang?/product-details/:product_number',
    name: 'product-details',
    component: () => import('@/pages/product-details/_product_number.vue'),
    meta: {
      title: 'Product details',
      static: true,
      needsLogin: true
    }
  },
  {
    path: '/:lang?/product-finder',
    name: 'product-finder',
    component: () => import('@/pages/product-finder.vue'),
    meta: {
      title: 'Product finder - Giftomatic',
      static: true,
      needsLogin: true,
      punchout: false
    }
  },
  {
    path: '/:lang?/basket',
    name: 'basket',
    component: () => import('@/pages/basket.vue'),
    meta: {
      title: 'Basket',
      static: true,
      needsLogin: true
    }
  },
  {
    path: '/:lang?/checkout/',
    redirect: (to) => {
      return '/';
    }
  },
  {
    path: '/:lang?/checkout/payment',
    name: 'checkout-payment',
    component: () => import('@/pages/checkout/payment.vue'),
    meta: {
      title: 'Checkout - Payment',
      static: true,
      needsLogin: true,
      punchout: false
    }
  },
  {
    path: '/:lang?/checkout/address',
    name: 'checkout-address',
    component: () => import('@/pages/checkout/address.vue'),
    meta: {
      title: 'Checkout - Address',
      static: true,
      needsLogin: true,
      punchout: false
    }
  },
  {
    path: '/:lang?/checkout/confirm',
    name: 'checkout-confirm',
    component: () => import('@/pages/checkout/confirm.vue'),
    meta: {
      title: 'Checkout - Confirm',
      static: true,
      needsLogin: true,
      punchout: false
    }
  },
  {
    path: '/:lang?/checkout/confirmation',
    name: 'checkout-confirmation',
    component: () => import('@/pages/checkout/confirmation.vue'),
    meta: {
      title: 'Checkout - Confirmation',
      static: true,
      needsLogin: false,
      showNavigation: false,
      punchout: false
    }
  },
  {
    path: '/:lang?/checkout/verify',
    name: 'checkout-verify',
    component: () => import('@/pages/checkout/verify.vue'),
    meta: {
      title: 'Checkout - Verify',
      static: true,
      needsLogin: true,
      punchout: false
    }
  },
  {
    path: '/:lang?/handshake-failed',
    name: 'handshake-failed',
    component: () => import('@/pages/handshake-error.vue'),
    meta: {
      title: 'Handshake - Failed',
      static: true,
      needsLogin: false,
      layout: 'Plain'
    }
  },
  {
    path: '/:lang?/handshake-logout',
    name: 'handshake-logout',
    component: () => import('@/pages/handshake-logout.vue'),
    meta: {
      title: 'Handshake - Logout',
      static: true,
      needsLogin: false,
      layout: 'Plain'
    }
  },
  {
    path: '/:lang?/payment-in-process',
    name: 'payment-in-process',
    component: () => import('@/pages/checkout/payment-in-process.vue'),
    meta: {
      title: 'Checkout - Payment in process',
      static: true,
      needsLogin: true,
      layout: 'Plain',
      punchout: false
    }
  },
  {
    path: '/:lang?/not-found',
    name: 'not-found',
    component: () => import('@/pages/error.vue'),
    meta: {
      title: 'Not found',
      static: true,
      error: 404,
      layout: 'Plain'
    }
  },
  {
    path: '/:lang?/error',
    name: 'error',
    component: () => import('@/pages/error.vue'),
    meta: {
      title: 'Error',
      static: true,
      error: 500,
      layout: 'Plain'
    }
  },
  {
    path: '/:lang?/error-open',
    name: 'error-open',
    component: () => import('@/pages/error-open.vue'),
    meta: {
      title: 'Error',
      static: true,
      layout: 'Plain'
    }
  },
  {
    path: '/:lang?/:pathMatch(.*)*',
    component: () => import('@/helpers/PageLoader.vue')
  }
];