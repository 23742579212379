import productTypes from "@/config/productTypes";
import i18n from '@/plugins/i18n';

// =======================================================================
// State
// =======================================================================
const state = () => ({
  etrackerId: null
});

// =======================================================================
// Mutations
// =======================================================================
const mutations = {
  SET_ETRACKER_ID(state, data) {
    state.etrackerId = data;
  }
};

// =======================================================================
// Actions
// =======================================================================
const actions = {
  et_eC_Wrapper(context, payload) {
    if (!context.state.etrackerId) return;
    window._etrackerOnReady.push(() => {
      et_eC_Wrapper(payload);
    });
  },

  etCommerce(context, { eventName, payload, number }) {
    if (!context.state.etrackerId) return;
    window._etrackerOnReady.push(() => {
      ['insertToBasket', 'removeFromBasket'].includes(eventName)
        ? etCommerce.sendEvent(eventName, payload, number)
        : etCommerce.sendEvent(eventName, payload);
    });
  },

  etCommerceRemoveAll(context) {
    if (!context.state.etrackerId) return;
    const basketItems = context.rootState.basket?.positions || [];
    basketItems.forEach(item => {
      const itemPrice =  item.gross_price / item.quantity;
      const product = context.rootGetters['product/getProduct'](item.product_number);
      const categories = context.rootGetters['etracker/getProductHierarchy'](item.product_number);
      const isReward = context.rootGetters['product/getProductTypReward'](item.product_number);
      const price = isReward ? product?.recommended_retail_price : item.face_value.amount;
      context.dispatch('etCommerce', {eventName: 'removeFromBasket', payload: {
        id: item.product_number,
        name: item.product_tracking_name,
        price: price.toFixed(2).toString(),
        discount: (price - itemPrice).toFixed(2).toString(),
        currency: 'EUR',
        category: categories,
        variants: isReward ? {} : {'var1': price.toFixed(2).toString()}
      }, number: item.quantity});
    });
  },

  etClick(context, { name, type }) {
    if (!context.state.etrackerId) return;	
    window._etrackerOnReady.push(() => {
      _etracker.sendEvent(new et_ClickEvent(name, type));
    });
  },

  addScript(context, data) {
    context.commit('SET_ETRACKER_ID', data);
    const configScript = document.createElement("script");
    configScript.setAttribute("type", "text/javascript");
    configScript.innerHTML = `
      window._etrackerOnReady = typeof _etrackerOnReady === 'undefined' ? [] : _etrackerOnReady;
    `;

    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.setAttribute("charset", "UTF-8");
    script.setAttribute("id", "_etLoader");
    script.setAttribute("data-block-cookies", "true");
    script.setAttribute("data-secure-code", data);
    script.setAttribute("async", "async");
    script.setAttribute("src", "//code.etracker.com/code/e.js");

    document.head.appendChild(configScript);
    document.head.appendChild(script);
  }
};


// =======================================================================
// Getters
// =======================================================================
const getters = {
  getProductHierarchy: (state, getters, rootState, rootGetters) => (productNumber) => {
    const product = rootGetters['product/getProduct'](productNumber);
    if(!product) return ['Additional reward'];

    const hierarchy = [];

    const productType = productTypes.find(type => type.id === product.type_group);
    if(productType) hierarchy.push(i18n.global.t(productType?.title, 'en' ));

    const shopCategories = rootState.app.websiteSettings.shop_categories;
    const productCategory = shopCategories.find(category => product.categories.includes(category.id));
    if(productCategory) hierarchy.push(productCategory.name);
    
    return hierarchy;
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
