<template>
  <div v-if="!product.sold_out" class="voucher-hints">
    <div class="detail-card detail-card__shipping-hints" :class="{ 'detail-card__shipping-hints--error': hasError }">
      <div class="row no-gutters">
        <div class="col-10">
          <div class="detail-card__title mb-3">{{ $t('view.product.hints.title') }}</div>
        </div>
        <div class="col-2">
          <a v-if="!isReward" @click="$scrollTo('#description', -150)" href="javascript:void(0)" class="float-right">{{
            $t('view.product.hints.link')
          }}</a>
        </div>
      </div>
      <!-- <div v-if="!product.redeemable_countries[1] && !isReward" class="row no-gutters voucher-hints__item-row">
        <div class="voucher-hint-wrapper">
          <b-icon class="voucher-hints__icon" icon="flags/de" font-scale="1.5" style="width: 20px; height: auto"/>
          <div class="voucher-hints__label">{{ $t('view.product.hints.country') }}</div>
        </div>
      </div> -->
      <div v-if="product.online_accepted_quantity && !isReward" class="row no-gutters voucher-hints__item-row">
        <div class="voucher-hint-wrapper">
          <span class="voucher-hints__icon accepted_quantity-icon">{{ product.online_accepted_quantity }}</span>
          <div class="voucher-hints__label">{{ $t('view.product.hints.amount', { accepted_count: product.online_accepted_quantity }) }}</div>
        </div>
      </div>
      <div v-if="product.offline_redeemable && !isReward" class="row no-gutters voucher-hints__item-row">
        <div class="voucher-hint-wrapper">
          <b-icon class="voucher-hints__icon" icon="shop" font-scale="1.5" />
          <div class="voucher-hints__label">{{ $t('view.product.hints.store') }}</div>
        </div>
      </div>
      <div v-if="product.online_redeemable && !isReward" class="row no-gutters voucher-hints__item-row">
        <div class="voucher-hint-wrapper">
          <b-icon class="voucher-hints__icon" icon="laptop" font-scale="1.5" />
          <div class="voucher-hints__label">{{ $t('view.product.hints.online') }}</div>
        </div>
      </div>
      <div v-if="product.usable_on_smartphone && !isReward" class="row no-gutters voucher-hints__item-row">
        <div class="voucher-hint-wrapper">
          <b-icon class="voucher-hints__icon" icon="phone" font-scale="1.5" />
          <div class="voucher-hints__label">{{ $t('view.product.hints.smartphone') }}</div>
        </div>
      </div>
      <div v-if="product.printable_as_barcode && !isReward" class="row no-gutters voucher-hints__item-row">
        <div class="voucher-hint-wrapper">
          <b-icon class="voucher-hints__icon" icon="printer" font-scale="1.5" />
          <div class="voucher-hints__label">{{ $t('view.product.hints.print') }}</div>
        </div>
      </div>
      <div v-if="product.redeemable_against_foreign_vouchers && !isReward" class="row no-gutters voucher-hints__item-row">
        <div class="voucher-hint-wrapper">
          <b-icon class="voucher-hints__icon" icon="exclamation-triangle" font-scale="1.5" />
          <div class="voucher-hints__label">{{ $t('view.product.hints.no_barzahlen') }}</div>
        </div>
      </div>
      <div v-if="isReward" class="row no-gutters voucher-hints__item-row">
        <div class="voucher-hint-wrapper">
          <b-icon class="voucher-hints__icon" icon="exclamation-triangle" font-scale="1.5" />
          <div class="voucher-hints__label">{{ $t('view.product.hints.shipping') }}</div>
        </div>
      </div>
    </div>
    <div class="voucher-hints__checkbox row no-gutters" :class="{ 'voucher-hints__checkbox--error': hasError }">
      <div class="col-12">
        <div class="custom-control custom-checkbox">
          <input id="voucher-hint-checkbox" v-model="isChecked" type="checkbox" class="custom-control-input" @change="toggleCheckbox" />
          <label for="voucher-hint-checkbox" class="custom-control-label">
            <div class="checkbox-label-wrapper">
              <div class="checkbox-label">{{ $t('view.product.hints.checkbox') }}</div>
              <div class="font-sm checkbox-label--error-msg" :class="{ 'd-none': !hasError }">
                {{ $t('view.product.hints.error') }}
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <h3 class="font-weight-bold mb-2 mt-5">{{ $t('view.product.state.sold_out') }}</h3>
    <p>{{ $t('view.product.state.sold_out_text') }}</p>
  </div>
</template>

<script>
  import BIcon from 'building-blocks/components/BIcon.vue';
  
  export default {
    components: {
      BIcon
    },
    props: {
      product: {
        type: Object,
        default() {
          return {};
        }
      },
      hasError: {
        type: Boolean,
        default: false
      },
      isReward: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        status: 'not_accepted',
        isChecked: false
      };
    },
    methods: {
      toggleCheckbox() {
        this.$emit('toggleCheckbox', this.isChecked);
      },
      reset() {
        this.isChecked = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  $width-height: 1.2rem;
  .accepted_quantity-icon {
    border: 1.4px solid #444;
    width: $width-height;
    height: $width-height;
    text-align: center;
    display: inline-block;
    @extend %font-sm;
  }
  .detail-card__shipping-hints {
    margin-bottom: 0;
    border: $border-width solid var(--primary);
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .voucher-hints {
    font-size: $h6-font-size;
    font-family: $font-semibold;
    &__item-row {
      border-bottom: $border-width solid $border-color;
      padding: 1rem 0;
      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
    &__icon {
      position: absolute;
      left: 0;
    }
    &__label {
      padding-left: 1rem;
    }
    &__checkbox {
      padding: 1rem;
      @include media-breakpoint-up(md) {
        padding: 1rem 1.5rem;
      }

      margin-bottom: 2rem;
      font-family: $font-semibold;
      background: var(--primary-lightest);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;

      .checkbox-label {
        font-size: $h5-font-size;
      }
      .checkbox-label--error-msg {
        width: 100%;
        font-size: $font-size-tiny;
      }
    }
    &__checkbox--error {
      background: var(--danger-lightest);
      color: $danger;
      border-top: 0;
    }
    .detail-card__shipping-hints--error {
      border: 1px solid var(--danger);
    }
  }

  .checkbox-label-wrapper {
    padding-left: 0.5rem;
  }

  .voucher-hint-wrapper {
    position: relative;
    padding-left: 1.5rem;
  }

  .detail-card__title {
    font-size: $h5-font-size;
  }
</style>
