<template>
  <div class="detail-card detail-card--no-border">
    <div class="order-bar d-flex justify-content-center">
      <select v-model="quantity" :disabled="quantityOptions.length === 1" class="order-bar__select custom-select custom-select-lg" @change="handleProductQuantity">
        <option v-for="option in quantityOptions" :key="option" :value="option">{{ option }}</option>
      </select>
      <DefaultButton
        block
        type="submit"
        variant="primary"
        size="lg"
        class="order-bar_button"
        icon-button
        :disabled="product?.sold_out"
        icon="cart3"
        scale="1.5"
        :button-text="$t('view.product.add_to_cart')"
        :loading="isLoading"
        data-test="add-to-basket"
      />
    </div>
  </div>
</template>

<script>
  import * as createSelectOptions from '@/helpers/createQuantitySelectOptions.js';
  import DefaultButton from 'building-blocks/components/DefaultElements/DefaultButton.vue';

  export default {
    components: {
      DefaultButton
    },
    props: {
      product: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        quantity: 1
      };
    },
    computed: {
      /**
       * Creates select options
       * first parameter defines how many select options are created
       * second parameter defines the initial selected option
       */
      quantityOptions() {
        return createSelectOptions.createOptions( this.$store.state.app.basketItemLimit, this.quantity);
      },
      isLoading() {
        return this.$store.state.app.isLoading;
      }
    },
    methods: {
      handleProductQuantity() {
        this.$emit('select', this.quantity);
      },
      reset() {
        this.quantity = 1;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .order-bar {
    &__select {
      width: 10rem;
      margin-right: 1rem;
      @include media-breakpoint-up(md) {
        margin-right: 1rem;
      }
      @include media-breakpoint-up(lg) {
        margin-right: 2rem;
      }
    }
    &__button {
      @extend %font-md;
      font-family: $font-semibold;
    }
  }

  @media screen and (max-width: 320px) {
    .order-bar_button {
      font-size: $btn-font-size-xs;
    }
  }
</style>
